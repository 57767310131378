import { useCallback } from 'react';
import AuthService from '../services/AuthService';
import BaseService from '../services/BaseService';
import { useErrors } from '../global-state/Errors';

const useUnauthenticate = () => {
  const setError = useErrors((x) => x.setValue);

  return useCallback(() => {
    return AuthService.logout().then(() => {
      setError(null);
      BaseService.unsetClientTenantDetails();
      BaseService.clearToken();
    });
  }, [setError]);
};

export default useUnauthenticate;
