import { create } from 'zustand';
import ErrorType from '../models/Error';

const { AUTH_DOWN, UNAUTHORIZED, FORBIDDEN, SERVER_ERROR, SERVICE_UNAVAILABLE, NOT_FOUND, UNKNOWN_ERROR } = ErrorType;

export interface ErrorStateProps {
  type: (typeof ErrorType)[keyof typeof ErrorType] | null;
  showContactSupport?: boolean;
  showRefresh?: boolean;
  showGoBack?: boolean;
  goBackCallback?: () => void;
  pageUrl?: string;
}

type Store = {
  value: ErrorStateProps | null;
  setValue: (newValue: ErrorStateProps | null) => void;
};

export const useErrors = create<Store>((set) => ({
  value: null,
  setValue: (newValue) => {
    if (newValue === null) {
      set({ value: newValue });
      return;
    }

    const value = { ...newValue };

    if (value.type) {
      if (([AUTH_DOWN, SERVER_ERROR, SERVICE_UNAVAILABLE, UNKNOWN_ERROR, NOT_FOUND] as string[]).includes(value.type)) {
        value.showRefresh = true;
        value.showContactSupport = true;
      } else if (([FORBIDDEN, UNAUTHORIZED] as string[]).includes(value.type)) {
        value.showGoBack = true;
      }
    }
    set({ value: { ...value, pageUrl: window.location.pathname } });
  },
}));
