import { Client } from '../models/Client';
import { simpleGlobalState } from './SimpleGlobalState';

export const useCurrentClient = simpleGlobalState<Client | null>(null);

export const useRegistrationClientId = simpleGlobalState<string | null>(null);

export const useCurrentTenantId = simpleGlobalState<string | null>(null);

export const useHideClients = simpleGlobalState(false);

export const useMyClients = simpleGlobalState<Client[]>([]);
